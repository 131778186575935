import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerBitly() {
    var url = ConfigAPI.baseURL + "bitly" + Session.getToken();
    return instance.get(url);
  },
  mostrarBitly(id) {
    var url = ConfigAPI.baseURL + "bitly/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarBitly(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "bitly" + Session.getToken();
    return instance.post(url,params);
  },
  editarBitly(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "bitly/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarBitly(id) {
    var url = ConfigAPI.baseURL + "bitly/"+ id + Session.getToken();
    return instance.delete(url);
  },   

  testBitly(data){
    var params = {      
      url: data.url,            
    }

    var url = ConfigAPI.baseURL + "bitly/test/url" + Session.getToken();
    return instance.post(url, params);
  },
}

export default servicesAPI;
