<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Bitly - Configuración</h4>
            <div class="small text-muted">Configurar la conexión para conectarnos al servicio</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      
            <b-tabs v-model="tabIndex" card>
              
              
              <b-tab title="Principal" :title-link-class="linkClass(0)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Bitly
                      <small>
                        <b-badge variant="success" v-if="crud.form.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>
                
                  <b-row>
                    <b-col md="6" sm="12" xs="12">
                      <b-row>                                                
                        <b-col md="8">                          
                          <b-form-group label="Token de Acceso">                                                                      
                            <b-form-input type="text"
                                          size="sm"
                                          autocomplete="off"
                                          v-model="crud.form.token">
                            </b-form-input>                          
                          </b-form-group>                             
                        </b-col>
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveBitly()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <hr>
                        </b-col> 
                        <b-col md="12">
                          <b-form-group label="URL">
                            <b-form-input type="url"
                                          size="sm"
                                          v-model="crud.form.url"                               
                                          placeholder="Ingrese una url">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="12" v-if="testURL" class="mb-2">
                          <b>URL acortda: <b-link :href="testURL" target="_blank">{{this.testURL}}</b-link></b>
                        </b-col> 
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-button variant="secondary" size="sm" @click="testBitly()">Probar Conexión</b-button>                          
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12" xs="12">
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Para obtener el token hay que crear un usuario con un email en  
                          <a href="https://bitly.com/" target="_blank">Bitly</a>                          
                          <br>
                          Luego, dentro del panel de control, en la configuración del perfil se encuentra la opción para generar el token.
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>              

            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.BITLY,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: { 
          form: {
            id: 1,         
            name: '',   
            token: '',
            active: false,
            url: '',
          },                    
        },   
        testURL: '',            
        tabIndex: 0,
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.showBitly()                
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      }, 
            
      showBitly() {       
        let loader = this.$loading.show(); 
        var result = serviceAPI.mostrarBitly(this.crud.form.id)

        result.then((response) => {
          loader.hide()
          var data = response.data
          
          this.crud.form.name = data.name          
          this.crud.form.token = data.token
          this.crud.form.active = data.active          
        }) 
        .catch(error => {         
          loader.hide() 
          this.$awn.alert(Error.showError(error));
        }) 
      },

      saveBitly() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de Bitly?', {
          title: 'Guardar Bitly',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();
            var result = serviceAPI.editarBitly(this.crud.form);

            result.then((response) => {          
              loader.hide()          
              this.showBitly()
              this.$awn.success("Datos de Bitly guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      testBitly() {            
        if(!this.crud.form.url) {
          this.$awn.alert("La url no puede estar vacia")
          return false
        }
        
        this.$bvModal.msgBoxConfirm('¿Probar configuración de Bitly?', {
          title: 'Configuración de Bitly',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.testBitly(this.crud.form)

            result.then((response) => {    
              this.testURL = response.data
              loader.hide()
              this.showBitly()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },         
    } 
  }
</script>
<style>
  .google-adwords-configuration-tracking-button {
    margin-top: 30px;
  }
  .google-adwords-configuration-label {
    font-weight: bold;
  }
</style>